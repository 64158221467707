@import "./scss/style.scss";

.areas {
    padding: 80rem 0;
}
.areas-content {
    @include flex-col;
    gap: 40rem;
    &__title h4 {
        @include text($ff, 700, 30rem, 38rem, $grayDark);
        text-align: center;
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(392rem, 1fr));
        gap: 20rem;
    }
    &__card {
        background-color: #d9d9d9;
        @include flex-col;
        & h3 {
            @include text($ff, 700, 30rem, 38rem, $white);
            margin: 4px 20px;
        }
    }
    &__card-img {
        background: $white;
        object-fit: cover;
        & img {
            width: 100%;
            height: 294px;
            object-fit: cover;
        }
    }
}