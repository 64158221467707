@import "./scss/style.scss";

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    @include flex-center;
    z-index: 1000;
}

.modal-content {
    background: $white;
    padding: 20rem;
    border-radius: $radiusS;
    width: 90%;
    max-width: 500px;
    position: relative;
    & h2 {
        @include text($ff, 500, 14rem, 20rem, $grayDark);
        margin-bottom: 20rem;
    }
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.form-group {
    margin-bottom: 20rem;
    & input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        border: 1px solid $gray;
        padding: 10rem 14rem;
        border-radius: 8px;
        @include text($ff, 400, 16rem, 24rem, $grayDark);
    }
}

.submit-btn {
    cursor: pointer;
    border-radius: $radiusS;
    display: inline-block;
    background-color: $primary;
    padding: 10rem;
    @include text($ff, 600, 16rem, 24rem, $white);
}

.submit-btn:disabled {
    background: #ccc;
}

.form-success {
    text-align: center;
    font-size: 18px;
    @include text($ff, 400, 16rem, 24rem, $grayDark);
}
