@import "./scss/style.scss";

.run {
    display: flex;
    flex-direction: column;
    margin-bottom: 80rem;
    transform: rotate(-3deg) translateY(0rem) translateX(0rem);
  
    &__line {
        overflow: hidden;
        background-color: $grayLight;
        position: relative;
        padding: 20rem 0;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
    }
  
    &__list {
        display: flex;
        flex-direction: row;
        gap: 20rem;
        // animation: 30s runner infinite linear;
    }
  
    &__slide {
        display: flex;
        vertical-align: top;
        align-items: center;
        width: 100%;
        margin: 0 36rem;
        height: auto;
        transition: $transition;
        gap: 12rem;
        @include text($ff, 700, 48rem, 60rem, $primary);
        @include tablet {
            margin: 0 50rem;
        }
    }
}
  
@keyframes runner {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}