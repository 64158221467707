@import "./scss/style.scss";

.header {
    @include flex-col;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
}
.header-settings {
    background-color: $grayDark;
    &__assets {
        @include flex-start;
        gap: 36rem;
        padding: 10px auto;
    }
    &__lang {
        display: flex;
        align-items: center;
        position: relative;
        align-items: center;
        gap: 3rem;
        &>button {
            @include text($ff, 700, 14rem, 20rem, $white);
        }
        &:hover {
            display: block;
        }
    }
    &__call, &__call a, &__email, &__email a {
        @include text($ff, 400, 14rem, 20rem, $white);
    }
}
.header-nav {
    @include flex-between;
    background-color: $white;
    width: 100%;
    padding: 10rem 20rem;
    &__logo {
    }
    &__logo-link {
        @include flex-start;
        gap: 10rem;
        & h1 {
            @include text($ff, 900, 36rem, 60rem, $primary);
        }
        & img {
            width: 40rem;
            height: 40rem;
        }
    }
    &__nav {
        display: flex;
        @include tablet-small {
            display: none;
        }
    }
    &__burger {
        display: none; // приховати бургер-меню на великих екранах
        background: none;
        border: none;
        cursor: pointer;
        padding: 10px;
        position: relative;
        z-index: 10;
        @include tablet-small {
            display: block;
        }
    
        .bar {
            display: flex;
            width: 25px;
            height: 3px;
            background: black;
            margin: 5px 0;
            transition: 0.3s;
            align-items: center;
            justify-content: center;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &__menu {
      display: flex;
      flex-direction: row;
  
      &.open {
        @include tablet-small {
            & .header-nav__nav {
                display: flex;
                flex-direction: column;
                width: 100%;
                position: absolute;
                top: 120rem;
                left: 0;
                background: $white;
                box-shadow: $shadow;
            }
        }
      }

      &.open .bar:nth-child(1) {
        transform: rotate(45deg);
        position: absolute;
      }
  
      &.open .bar:nth-child(2) {
        opacity: 0;
      }
  
      &.open .bar:nth-child(3) {
        transform: rotate(-45deg);
        position: absolute;
      }
    }
    &__ul {
        @include flex-center;
        gap: 16rem;
        padding: 20rem;
    }
    &__li {
        position: relative;
        .chevron {
            margin-left: 5px;
        }
    }
    &__li:hover .chevron {
        transform: rotate(180deg);
        transition: $transition;
    }

    &__li a, h4{
        @include text($ff, 500, 16rem, 24rem, $grayDark);
        @include flex-center;
        cursor: pointer;
        @include tablet-small {
            @include text($ff, 500, 20rem, 30rem, $grayDark);
        }
    }
}
.dropdown-content {
    display: none;
    position: absolute;
    background-color: $white;
    min-width: 160px;
    box-shadow: $shadow;
    border-radius: $radius;
    z-index: 1;
    a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        &:hover {
            background-color: $grayLight;
        }
    }
}
.dropdown:hover .dropdown-content {
    display: block;
}