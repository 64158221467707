@import "./scss/style.scss";

.sensors {
    padding: 80rem 0;
}
.sensors-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40rem;

    & h1 {
        @include text($ff, 700, 30rem, 38rem, $grayDark);
    }

    &__list {
        @include flex-between;
        width: 100%;
        @include tablet-small {
            flex-wrap: wrap;
        }
    }

    &__card {
        @include flex-col;
        align-items: center;
        padding: 16rem 32rem 16rem;
        gap: 10rem;
        cursor: pointer;
        border: 1px solid $grayLight;
        border-radius: $radius;
        &:hover {
            box-shadow: $shadow;
            transition: $transition;
        }

        & img {
            width: 113rem;
            height: auto;
        }
        & h2 {
            @include text($ff, 700, 18rem, 28rem, $grayDark);
        }

    }
}