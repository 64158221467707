@import "./scss/style.scss";

.main {
    padding-top: 114rem;
    margin-top: 120rem;
    background-image: url(../../public/mainBg.webp);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 700rem;
    @include tablet-small {
        width: 100%;
        height: 1000rem;
        padding: 40rem 20rem;
    }
}
.main-content {
    background-color: $white;
    border-radius: $radius;
    margin-inline: auto;
    padding: 48rem 40rem;
    max-width: 1216rem;
    max-height: 456rem;
    @include flex-between;
    @include tablet-small {
        @include flex-col;
        gap: 20rem;
        max-width: 400px;
        max-height: 1000px;
    }

    &__text {
        @include flex-col;
        gap: 24rem;
        max-width: 450rem;
        & h3 {
            @include text($ff, 400, 16rem, 24rem, $grayDark);
        }
        & h1 {
            @include text($ff, 700, 60rem, 72rem, $grayDark);
            margin-bottom: 16rem;
            & span {
                color: $primary;
            }
        }
    }

    &__img img{
        height: 360rem;
        width: auto;
    }
}