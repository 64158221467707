@import "./scss/style.scss";

.dropdown {
    &__content {
        display: none;
        position: absolute;
        background: $grayDark;
        border-radius: $radius;
        width: auto;
        height: auto;
        box-shadow: $shadow;

        & button {
            cursor: pointer;
            padding: 12rem 16rem;
            text-decoration: none;
            display: inline-block;
            background-color: transparent;
            @include text($ff, 400, 14rem, 20rem, $white);
            text-transform: uppercase;
            transition-property: transform;
            transition: $transition;

            &::after {
                content: '';
                border-top: 2rem solid $primary;
                width: 80%;
                position: absolute;
                display: block;
                transform: rotateY(90deg);
                transition:transform 0.25s linear;
            }

            &:hover {
                transform: scale(1);
            }
            &:hover::after {
                transform: rotate(0deg);
            }
        }
    }
}
.dropbtn {
    background-color: transparent;
    padding: 10rem 0;
    border: none;
    cursor: pointer;
    transition: $transition;
    text-transform: uppercase;
    display: flex;
    gap: 8rem;
    img {
        width: 20rem;
        height: 20rem;
    }
    &:hover .chevron{
        transform: rotate(180deg);
    }
}

.dropdown:hover .dropdown__content {
    display: flex;
    flex-direction: column;
    gap: 12rem;
    padding: 24rem 12rem;
}