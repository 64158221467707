@import "./scss/style.scss";

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
  *::-webkit-scrollbar {
    width: 7rem;
    background-color: #ffffff00;
}
  *::-webkit-scrollbar-thumb {
    border-radius: 7rem;
    background-color: $primary;
}
  ::-moz-selection {
    color: $white;
    background: $grayDark;
}
  ::selection {
    color: $white;
    background: $grayDark;
}