@import "./scss/style.scss";

.connection {
    margin-top: 120rem;
    padding-top: 80rem;
    margin-bottom: 80rem;
}
.connection-content {
    @include flex-col;
    gap: 40rem;
    & h3 {
        @include text($ff, 400, 36rem, 40rem, $grayDark);
        text-align: center;
    }
    &__main {
        padding: 20rem 0;
        @include flex-between;
        gap: 20rem;
    }
    &__txt p {
        @include text($ff, 400, 20rem, 24rem, $grayDark);
        max-width: 473rem;
    }
    &__img {
        @include flex-col;
        align-items: center;
        gap: 20rem;
        max-width: 630rem;
        & img {
            width: 280rem;
            height: auto;
        }
        & p {
            @include text($ff, 400, 20rem, 24rem, $grayDark);
            text-align: center;
        }
    }
    &__desc {
        @include flex-col;
        gap: 20rem;
        & p {
            @include text($ff, 400, 20rem, 24rem, $grayDark);
        }
    }
    &__list {
        @include flex-col;
        gap: 20rem;
        & h4 {
            @include text($ff, 400, 20rem, 24rem, $grayDark);
        }
        & ul {
            @include flex-col;
            gap: 10rem;
        }
        & li {
            @include text($ff, 400, 20rem, 24rem, $grayDark);
            list-style: disc;
            margin-left: 30rem;
        }
    }
    &__connected {
        padding: 20rem 0;
        @include flex-between;
        gap: 20rem;
    }
    &__pic {
        @include flex-col;
        align-items: center;
        gap: 20rem;
        max-width: 515rem;
        & img {
            width: 400rem;
            height: auto;
        }
        & p {
            @include text($ff, 400, 20rem, 24rem, $grayDark);
            text-align: center;
        }
    }
    &__info p {
        @include text($ff, 400, 20rem, 24rem, $grayDark);
        max-width: 493rem;
    }

    &__text {
        @include flex-col;
        gap: 20rem;
        & p {
            @include text($ff, 400, 20rem, 24rem, $grayDark);
        }
    }
}