@import "./scss/style.scss";

.setup {

}
.setup-main {
    padding-top: 114rem;
    margin-top: 120rem;
    background-image: url(../../public/icons/grayArrows.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: $gray;
    width: 100vw;
    height: auto;
    padding: 80rem 0;

    &__table {
        @include flex-col;
        gap: 60rem;
        margin-inline: auto;
        align-items: flex-start;
        
    }

    &__title {
        @include text($ff, 400, 36rem, 40rem, $grayDark);
        text-align: center;
    }
    & table {
        @include text($ff, 400, 16rem, 24rem, $grayDark);
        & tr {
            @include flex-between;
            gap: 120rem;
            &:hover {
                background: $white;
            }
            @include tablet-small {
                gap: 40rem;
            }
        }
    }
    & p {
        @include text($ff, 400, 16rem, 20rem, $grayDark);
    }
}
.setup-txt {
    @include flex-col;
    gap: 20rem;
    padding-top: 80rem;
    padding-bottom: 40rem;

    &__title {
        @include text($ff, 400, 36rem, 40rem, $grayDark);
        text-align: center;
        margin-bottom: 20rem;
    }

    &__desc {
        @include text($ff, 400, 20rem, 24rem, $grayDark);
    }
}

.setup-instruction {
    background-color: $gray;
    padding: 20rem 0;
    &:nth-child(even) {
        background-color: $white;
        & .setup-instructions__content {
            flex-direction: row-reverse;
            @include tablet-small {
                @include flex-col;
                gap: 40rem;
            }
        }
    }
}
.setup-instructions {
    @include flex-col;
    gap: 20rem;

    &__content {
        @include flex-between;
        gap: 20rem;
        @include tablet-small {
            @include flex-col;
            gap: 40rem;
        }
    }

    &__img {
        @include flex-col;
        align-items: center;
        gap: 20rem;
        & img {
            max-width: 630rem;
            height: auto;
            @include tablet-small {
                width: 100%;
            }
        }
        & p {
            @include text($ff, 400, 20rem, 24rem, $grayDark);
            text-align: center;
            max-width: 630rem;
        }
    }

    &__txt {
        // max-width: 403rem;
        & p {
            @include text($ff, 400, 20rem, 24rem, $grayDark);
        }
    }
    
    &__sub p {
        @include text($ff, 400, 20rem, 24rem, $grayDark);
    }
}
.setup-twoFr {
    @include flex-between;
    gap: 140rem;
    margin: 20rem auto;
    @include tablet-small {
        @include flex-col;
        gap: 40rem;
    }

    &__txt {
        @include flex-col;
        gap: 20rem;
        width: 455rem;
    }

    &__title h4 {
        @include text($ff, 400, 20rem, 24rem, $grayDark);
    }
    &__list ul {
        @include flex-col;
        gap: 12rem;
        & li {
            @include text($ff, 400, 16rem, 20rem, $grayDark);
        }
    }

    &__warning {
        background-color: $primary;
        padding: 20rem;
        width: 617rem;
        @include tablet-small {
            width: auto;
        }
        & p {
            @include text($ff, 400, 20rem, 24rem, $black);
            & span {
                color: $white;
            }

        }
    }
}
.setup-list {
    margin-top: 40rem;

    &__ul {
        @include flex-col;
        gap: 20rem;
    }

    &__li {
        @include text($ff, 400, 20rem, 30rem, $black);
    }
}