@import "./scss/style.scss";

.about {
    background-image: url(../../public/icons/grayArrows.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: $gray;
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    padding: 80rem 0;

}
.about-content {
    @include flex-col;
    gap: 16rem;
    margin-inline: auto;
    align-items: center;

    &__title {
        @include text($ff, 400, 60rem, 72rem, $grayDark);
        text-align: center;
    }

    &__info {
        @include text($ff, 400, 20rem, 24rem, $grayDark);
        text-align: center;
        max-width: 600rem;
    }
}