$ff: "Roboto", sans-serif;;

$black: #101010;
$gray: #EAECF0;
$grayLight: #F2F4F7;
$grayDark: #1D2939;
$white: #FFFFFF;
$primary: #41BCCB;
$accent: #FDB022;

$radius: 16px;
$radiusS: 8px;
$btnRadius: 50px;

$shadow: 0 8rem 16rem 0 rgba( 25, 25, 221, 0.1 );

$transition: .2s all ease-in-out;

$transY: translateY(10rem);

$desktop: 1365px;
$desktop-small: 1200px;
$tablet: 992px;
$tablet-small: 768px;
$mobile: 576px;
$mobile-small: 370px;