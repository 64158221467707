@import "./scss/style.scss";

.footer {
    padding: 60rem 0;
}
.footer-content {
    @include flex-col;
    gap: 60rem;
    &__section {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 160rem;
        @include tablet-small {
            gap: 20rem;
        }
        @include mobile {
            @include flex-col;
            gap: 40rem;
        }
    }
    &__links {
        @include flex-col;
        gap: 32rem;
    }
    &__logo {
        @include flex-col;
        gap: 32rem;
        & p {
            @include text($ff, 400, 16rem, 24rem, $grayDark);
        }
    }
    &__img {
        @include flex-start;
        gap: 10rem;
        & h1 {
            @include text($ff, 900, 36rem, 60rem, $primary);
        }
        & img {
            width: 60rem;
            height: 60rem;
        }
    }
    &__nav {
    }
    &__ul {
        @include flex-col;
        gap: 12rem;
    }
    &__li:hover .chevron {
        transform: rotate(90deg);
        transition: $transition;
    }
    &__li a {
        @include text($ff, 600, 16rem, 24rem, $grayDark);
    }
    &__li h4 {
        @include text($ff, 600, 16rem, 24rem, $grayDark);
        display: flex;
        align-items: center;
        & img {
            margin-left: 10rem;
            width: 20rem;
            height: 20rem;
            transform: rotate(270deg);
            transition: $transition;
        }
    }
    &__contacts {
        @include flex-col;
        gap: 32rem;
        & a {
            @include text($ff, 400, 16rem, 20rem, $grayDark);
        }
    }
    &__forms {
        @include flex-col;
        gap: 16rem;
        & h4 {
            @include text($ff, 500, 14rem, 20rem, $grayDark);
        }
        & form {
            @include flex-col;
            gap: 16rem;
            & input {
                width: 225rem;
                border: 1px solid $gray;
                padding: 10rem 14rem;
                border-radius: 8px;
                @include text($ff, 400, 16rem, 24rem, $grayDark);
            }
            & button {
                cursor: pointer;
                border-radius: 8px;
                display: inline-block;
                background-color: $primary;
                padding: 10rem;
                @include text($ff, 600, 16rem, 24rem, $white);
            }
        }
    }
    &__copyright {
        @include flex-between;
        padding-top: 32rem;
        border-top: 1px solid $gray;
        @include tablet-small {
            @include flex-col;
            gap: 40rem;
        }
    }
}
.copyright {
    @include text($ff, 400, 16rem, 24rem, #667085);
    & a {
        color: $black;
    }
}
.dropdown-footer {
    position: relative;
    display: inline-block;
}
.dropdown-content-footer {
    display: none;
    position: absolute;
    background-color: $white;
    min-width: 160px;
    box-shadow: $shadow;
    border-radius: $radius;
    z-index: 1;
    top: -100rem;
    left: 45%;
    a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        &:hover {
            background-color: $grayLight;
        }
    }
}
.dropdown-footer:hover .dropdown-content-footer {
    display: block;
}