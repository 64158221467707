@import "./scss/style.scss";

.advantages {
    background-image: url(../../public/blurBg.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100vw;
    &__blur {
        background-color: #10182801;
        backdrop-filter: blur(200px);
        -webkit-backdrop-filter: blur(200px);
    }
}
.advantages-content {
    width: 100%;
    padding-top: 80rem;
    padding-bottom: 80rem;
    @include flex-between;
    gap: 40rem;
    @include tablet {
        @include flex-col;
    }

    &__col1 {
        max-width: 547rem;
        @include flex-col;
        gap: 120rem;
        @include tablet {
            gap: 50rem;
        }
    }

    &__header {
        @include flex-col;
        gap: 60rem;
        & h4 {
            @include text($ff, 700, 60rem, 72rem, $grayDark);
        }
    }

    &__header-bloc {
        @include flex-start;
        gap: 33rem;
        & img {
            width: 72rem;
            height: 72rem;
        }
    }

    &__header-plus {
        border-left: 2rem solid #41bbcb64;
        padding-left: 33rem;
        & h3 {
            @include text($ff, 400, 48rem, 60rem, $primary);
        }
        & p {
            @include text($ff, 400, 20rem, 30rem, $primary);
        }
    }

    &__txt p {
        @include text($ff, 400, 20rem, 24rem, $grayDark);
    }

    &__col2 {
        max-width: 592rem;
    }

    &__list {
        @include flex-col;
        gap: 32rem;
    }

    &__card {
        background-color: $white;
        padding: 40rem;
        @include flex-start;
        gap: 28rem;
        border-radius: $radius;
        &>img {
            width: 64rem;
            height: 64rem;
        }
        & ul {
            @include flex-col;
            gap: 8rem;
            & li {
                @include text($ff, 500, 20rem, 30rem, $grayDark);
            }
        }
    }
}