@import "./scss/style.scss";

*,
*::before,
*::after {
    box-sizing: border-box;
}

#root {
    width: 100%;
    height: 100vh;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    line-height: inherit;
}

html {
    font-size: 1px;
    margin-inline: auto;
    @include desktop-small {
        font-size: 0.9px;
    }
    @include tablet {
        font-size: 0.8px;
    }
    @include tablet-small {
        font-size: 0.7px;
    }
    @include mobile {
        font-size: 0.7px;
    }
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}
body {
    font-family: $ff;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-size: 10rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
a {
    text-decoration: none;
}
ul,
li {
    text-decoration: none;
    list-style: none;
}
img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    user-select: none;
}
button {
    background-color: transparent;
}
.wrapper {
    max-width: 1440rem;
    height: auto;
    margin-inline: auto;
    padding: 0 112rem;
    @include desktop {
        padding: 0 20rem;
        // margin: 0 20rem;
    }
}
.title {
    @include text($ff, 500, 36rem, 42rem, $black);
}

.desc {
    @include text($ff, 400, 20rem, 24rem, $grayDark);
}

.btn {
    @include flex-center;
    gap: 8rem;
    padding: 16rem 30rem;
    background-color: $grayDark;
    border: 0;
    border-radius: $btnRadius;
    cursor: pointer;
    transition: $transition;
    width: fit-content;
    @include text($ff, 700, 16rem, 20rem, $white);
    &:hover {
        box-shadow: $primary 0rem 7rem 29rem 0rem;
        color: $primary;
        transition: $transition;
        & img {
            transform: translateX(5rem);
            transition: $transition;
        }
        &::-moz-selection {
            color: $primary;
            background: $grayDark;
        }
        &::selection {
            color: $primary;
            background: $grayDark;
        }
    }
    &:active {
        transform: $transY;
        box-shadow: $primary 0rem 0rem 0rem 0rem;
        transition: $transition;
    }
}