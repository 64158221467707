@import "./scss/style.scss";

.video {
    padding: 80rem 0;
}
.video-content {
    @include flex-col;
    gap: 40rem;
    align-items: center;

    &__title {
        @include text($ff, 700, 48rem, 60rem, $grayDark);
    }
    video {
        background: $black;
        height: 70vh;
        width: 60vw;
    }
}