@import "./scss/style.scss";
@import 'prismjs/themes/prism-okaidia.css';

.code-container {
    border-radius: $radiusS;
    font-size: 16rem;

    &__content {
        overflow: hidden;
        background: #565656;
        border-radius: $radiusS;
    }
}
.code-header {
    @include flex-between;
    gap: 20rem;
    padding: 10px 20px;
    & p {
        @include text($ff, 400, 20rem, 26rem, $gray);
    }
}
.copy-button {
    display: flex;
    cursor: pointer;
    gap: 8rem;
    align-items: center;
    @include text($ff, 400, 20rem, 26rem, $gray);
    & img {
        width: 20rem;
        height: auto;
    }
}
