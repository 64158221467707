@import "./scss/style.scss";

.draft {
    margin-top: 200rem;
    margin-bottom: 80rem;
}
.draft-content {
    @include flex-col;
    gap: 40rem;
    &__main {
        @include flex-col;
        gap: 40rem;
        margin-bottom: 20rem;
        & h1 {
            @include text($ff, 400, 36rem, 40rem, $grayDark);
            text-align: center;
        }
    }
    &__pics {
        @include flex-center;
        gap: 20rem;
        @include mobile {
            @include flex-col;

        }
        & img {
            width: 300rem;
            height: auto;
        }
    }
    &__draft {
        @include flex-col;
        gap: 20rem;
        & img {
            width: 1000rem;
            height: auto;
        }
        & p {
            @include text($ff, 400, 20rem, 24rem, $grayDark);
            text-align: center;
        }
    }
    &__draft-small {
        @include flex-between;
        gap: 20rem;
        @include tablet {
            flex-direction: column-reverse;

        }

        & img {
            width: 600rem;
            height: auto;
        }
        & p {
            @include text($ff, 400, 20rem, 24rem, $grayDark);
            text-align: center;
        }
    }
    &__title {
        @include text($ff, 400, 36rem, 40rem, $grayDark);
        text-align: center;
    }
}