@import "./scss/style.scss";

.faq {
    margin: 120rem 0 80rem;
    padding-top: 80rem;
}
.faq-content {
    @include flex-col;
    gap: 40rem;
    & h4 {
        @include text($ff, 400, 36rem, 40rem, $grayDark);
        text-align: center;
    }
    &__list {
        @include flex-col;
        gap: 20rem;
    }
    &__card {
        border: 1px solid $black;
        padding: 16rem 30rem;
        @include flex-col;
        gap: 16rem;
        border-radius: $radius;
    }
    &__question {
        @include flex-between;
        cursor: pointer;
        & h5 {
            @include text($ff, 400, 20rem, 24rem, $grayDark);
        }
        & img {
            width: 24rem;
            height: 24rem;
        }
    }
    &__answer {
        display: none;
        border-top: 1px solid #000;
        padding-top: 16rem;
        & p {
            @include text($ff, 400, 20rem, 24rem, $grayDark);
        }
    }
}
.faq-content__card:hover img {
    transform: rotate(180deg);
    transition: $transition;
}
.faq-content__card:hover .faq-content__answer {
    display: flex;
    transition: $transition;
}