@import "./scss/style.scss";
@import 'prismjs/themes/prism-okaidia.css';


.programm {
    margin-top: 200rem;
    margin-bottom: 80rem;
}
.programm-content {
    @include flex-col;
    gap: 40rem;
    & h3 {
        @include text($ff, 400, 36rem, 40rem, $grayDark);
        text-align: center;
    }

    &__table-one {
        & h4 {
            @include text($ff, 400, 20rem, 24rem, $grayDark);
            margin-bottom: 10rem;
        }
        & th {
            text-align: start;
            padding: 10rem;
        }
        & table tr:hover {
            background-color: $gray;
            transition: $transition;
        }
        & table thead {
            background-color: $black;
            @include text($ff, 400, 20rem, 24rem, $white);
            & tr:hover {
                background-color: $grayDark;
                transition: $transition;
            }
        }
        & table tbody {
            @include text($ff, 400, 20rem, 24rem, $black);
        }
        & td {
            text-align: start;
            padding-right: 30rem;
        }
    }

    &__draft {
        max-width: 1216rem;
        height: auto;
    }

    &__table-two {
        & h4 {
            @include text($ff, 400, 20rem, 24rem, $grayDark);
            margin-bottom: 10rem;
        }
        & table tr:hover {
            background-color: $gray;
            transition: $transition;
        }
        & table tbody {
            @include text($ff, 400, 20rem, 24rem, $black);
        }
        & td {
            text-align: start;
            padding-right: 30rem;
        }
    }

    &__code {
        @include text($ff, 400, 20rem, 24rem, $black);
    }

    &__info {
        @include flex-between;
        gap: 20rem;
        @include tablet-small {
            @include flex-col;
            gap: 40rem;
        }
    }

    &__list {
        @include flex-col;
        gap: 20rem;
        & p {
            @include text($ff, 400, 20rem, 24rem, $black);
        }
        &>ul {
            @include flex-col;
            gap: 10rem;
            @include text($ff, 400, 20rem, 24rem, $black);
            &>li {
                list-style:disc;
                margin-left: 30rem;
                &>ul {
                    @include flex-col;
                    gap: 10rem;
                    margin-left: 30rem;
                    margin-top: 10rem;
                }
            }
        }
    }

    &__warning {
        background-color: $primary;
        width: 600rem;
        padding: 20rem;
        @include tablet-small {
            width: 100%;
        }
        & p {
            @include text($ff, 400, 20rem, 24rem, $black);
            & span {
                color: $white;
            }
        }
    }

    &__title {
        @include text($ff, 600, 20rem, 24rem, $black);
    }
    &__ul {
        @include text($ff, 400, 20rem, 40rem, $black);

    }
    &>p {
        @include text($ff, 400, 20rem, 24rem, $black);
    }
}