@import "./scss/style.scss";

.steps-content {
    @include flex-col;
    margin: 80rem 0;
    gap: 40rem;
    margin-inline: auto;
    &>h3 {
        @include text($ff, 700, 48rem, 60rem, $grayDark);
    }
    &__bloc {
        @include flex-col;
        gap: 32rem;
    }
    &__card {
        background-color: $grayLight;
        @include flex-col;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 388rem;
        position: relative;
        overflow: hidden;
        padding: 40rem 0 80rem 0;
        border-radius: $radius;
        & h4 {
            @include text($ff, 600, 20rem, 30rem, $grayDark);
            margin-bottom: 16rem;
        }
        & h3 {
            @include text($ff, 400, 60rem, 72rem, $primary);
            width: 385rem;
            text-align: center;
            margin-bottom: 32rem;
        }
    }
    &__card-img1, &__card-img2 {
        position: absolute;
        width: 500rem;
        height: auto;
        transform: rotate(345deg);
        @include tablet {
            display: none;
        }
    }
    &__card-img1 {
        top: 0;
        left: -60rem;
    }
    &__card-img2 {
        bottom: 0;
        right: -34rem;
    }
    &__cards {
        display: flex;
        gap: 32rem;
        @include mobile {
            @include flex-col;
        }
    }
    &__cards-small1, &__cards-small2 {
        @include flex-col;
        width: 100%;
        height: 558rem;
        position: relative;
        overflow: hidden;
        padding: 40rem 167rem 307rem 40rem;
        border-radius: $radius;
        z-index: 2;
        @include tablet {
            height: auto;
            padding: 20rem;
        }
        & h4 {
            @include text($ff, 600, 20rem, 30rem, $white);
            margin-bottom: 16rem;
        }
        & h3 {
            @include text($ff, 400, 36rem, 44rem, $grayDark);
            width: 385rem;
            margin-bottom: 32rem;
            @include tablet {
                width: auto;
            }
        }
        & a button {
            z-index: 10;
        }
        &::after {
            position: absolute;
            content: '';
            bottom: -380rem;
            left: 0;
            width: 792rem;
            height: 792rem;
            z-index: -10;
            background: radial-gradient(circle, rgba(250,250,250,1) 0%, rgba(255,255,255,0) 50%);
        }
        &>img {
            position: absolute;
            z-index: -3;
            @include tablet {
                display: none;
            }
        }
    }
    &__cards-small1 {
        background-color: $accent;
        &>img {
            width: 700rem;
            height: auto;
            bottom: -340rem;
            transform: rotate(15deg);
        }
    }
    &__cards-small2 {
        background-color: $primary;
        &>img {
            width: auto;
            height: 378rem;
            bottom: -10rem;
            left: 230rem;
            transform: rotate(357deg);
        }
    }
}